import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/services/auth/authentication.service';
// import { EncryptDecryptService } from 'src/app/shared/services/encyptDecrypt/encrypt-decrypt.service';
import { EncryptDecryptService } from 'src/app/shared/services/encryptDecrypt/encrypt-decrypt.service';
import $ from 'jquery' ;

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit {

  // variable declaration
  userData: any = []
  isAdminUser: boolean = false;
  firstName: string;
  firstLetter: string;
  username: string;
  isShow:boolean;
  miniShow:boolean;
  user:any

  constructor(
    private _authService: AuthenticationService,
    private _router: Router,
    private _encryptDecrypt: EncryptDecryptService,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
  
    this.data();
    if (this._authService.isLoggedIn && this._authService.isAdmin) {
      this.isAdminUser = true
    }
  }



  data(){
    //console.log("Fetching the data");
    if (this._authService.isLoggedIn) {
      this.userData = this._encryptDecrypt.decryptToken();
      this.firstName = this.userData.first_name;
      this.firstLetter = this.firstName.charAt(0).toUpperCase();
      this.username = this.userData.username;

    }
  }

    // logout function
    logout() {
      this._authService.logout()
      this._router.navigate([''])
    }

    toggledropdown(){
      this.isShow=!this.isShow;

    }
    toggleSidebarCollapse() {
      const body = document.getElementsByTagName('body')[0];

      if(body.classList.contains('sidebar-collapse')){
        body.classList.remove('sidebar-collapse');
      }
      else{
        body.classList.add('sidebar-collapse');
      }
    
      
 
    }

   
    }

