import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { AuthenticationService } from 'src/app/shared/services/auth/authentication.service';
import { EncryptDecryptService } from 'src/app/shared/services/encryptDecrypt/encrypt-decrypt.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        private _router: Router,
        private _authenticationService: AuthenticationService,
        private _encryptService: EncryptDecryptService,
    ) { }

    // canActivate(
    //     route: ActivatedRouteSnapshot,
    //     state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    //     // Decrypt the user token
    //     const current_user = this._encryptService.decryptToken();

    //     // If the user is logged in
    //     if (current_user) {
    //         // Check if the route requires certain roles
    //         if (route.data.roles && route.data.roles.indexOf(current_user.type) === -1) {

    //             // Decrypt the data from localStorage
    //             const eText = JSON.parse(localStorage.getItem('currentUser'));
    //             const decryptedWord = CryptoJS.AES.decrypt(eText, 'my_token');
    //             const decryptedData = JSON.parse(decryptedWord.toString(CryptoJS.enc.Utf8));

    //             // Check if the role is allowed to access the route
    //             if (route.data.roles && route.data.roles.indexOf(decryptedData.role) === -1) {
    //                 // Redirect to 'not-authorized' page with query params
    //                 this._router.navigate(['not-authorized'], {
    //                     queryParams: { returnUrl: state.url, error: 'unauthorized-role' }
    //                 });
    //                 return false;
    //             }
    //         }

    //         // Authorized so return true
    //         return true;
    //     }

    //     // If the user is not logged in, redirect to the login page with the returnUrl
    //     this._router.navigate(['not-authorized'], { queryParams: { returnUrl: state.url } });
    //     return false;
    // }


    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const isScanned = route.queryParams['isScanned'];
        // If isScanned is true, trigger auto-login
        if (isScanned === 'true') {
            return this._authenticationService.autoLogin().then((success) => {
                if (success) {
               
                    return true;
                } else {
                    this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                    return false;
                }
            });
        }

        // Decrypt the user token
        const current_user = this._encryptService.decryptToken();

        // If the user is logged in
        if (current_user) {
            // Check if the route requires certain roles
            if (route.data.roles && route.data.roles.indexOf(current_user.type) === -1) {

                // Decrypt the data from localStorage
                const eText = JSON.parse(localStorage.getItem('currentUser'));
                const decryptedWord = CryptoJS.AES.decrypt(eText, 'my_token');
                const decryptedData = JSON.parse(decryptedWord.toString(CryptoJS.enc.Utf8));

                // Check if the role is allowed to access the route
                if (route.data.roles && route.data.roles.indexOf(decryptedData.role) === -1) {
                    // Redirect to 'not-authorized' page with query params
                    this._router.navigate(['not-authorized'], {
                        queryParams: { returnUrl: state.url, error: 'unauthorized-role' }
                    });
                    return false;
                }
            }

            // Authorized so return true
            return true;
        }
        if (isScanned) {
            console.log("isScanned1:", isScanned)

            // If the user is not logged in, redirect to the login page with the returnUrl
            this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        } if (!isScanned) {
            console.log("isScanned12:", isScanned)

            // If the user is not logged in, redirect to the login page with the returnUrl
            this._router.navigate(['not-authorized'], { queryParams: { returnUrl: state.url } });
            return false;
        }

    }
}
